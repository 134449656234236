import React, { useState } from 'react'
import { StyledContainer } from './TermsAndConditions.styles'
import BackgroundScene from '@views/shared/BackgrounScene'
import { AccessControl } from '@root/src/views/shared/AccessControl/AccessControl'
import TermsAndConditionsContent from './TermsAndConditionsContent'

const TermsAndConditions = () => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false)

  const handleBackgroundLoad = () => {
    setBackgroundLoaded(true)
  }

  return (
    <StyledContainer>
      <BackgroundScene onLoad={handleBackgroundLoad}>
        {backgroundLoaded && <TermsAndConditionsContent />}
      </BackgroundScene>
    </StyledContainer>
  )
}

export default TermsAndConditions

export const TermsAndConditionsWithAccessControl = () => <AccessControl component={TermsAndConditions} />
