import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { INITIATIVE_LOG_PAGE_SIZE } from '@domain/myImoClient/common/initiativeActivityLog/constants'
import { unionBy } from 'lodash'

import type { ReduxState, ReduxAction, ActivityLog, Action } from './reducerTypes'

export const initialReducerState = {}
export const emptyActivityLog = { hasMore: true, items: [] }

const byCreatedAt = (a: Action, b: Action) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()

const mergeExistingAndUpdatedDataSorted = (existing: ActivityLog, updated: ActivityLog) =>
  unionBy(existing, updated, 'id').sort(byCreatedAt)

const initiativeActivityLogReducer = handleActions<ReduxState, ReduxAction>(
  {
    [constants.FETCH_INITIATIVE_ACTIVITY_LOG]: (state, action) => {
      const { initiativeId } = action.payload

      return { ...state, [initiativeId]: emptyActivityLog }
    },
    [constants.SET_INITIATIVE_ACTIVITY_LOG]: (state, action) => {
      const { initiativeId, data, offset } = action.payload
      const initiativeUnderModification = state[initiativeId]
      const hasMore = data.total > offset + INITIATIVE_LOG_PAGE_SIZE

      const existingForInitiative = initiativeUnderModification || emptyActivityLog
      const items = mergeExistingAndUpdatedDataSorted(existingForInitiative.items, data.logs)

      return { ...state, [initiativeId]: { items, hasMore } }
    },
  },
  initialReducerState,
)

export default initiativeActivityLogReducer
