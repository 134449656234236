import { api } from '@root/src/common/net'
import { RealTimeContext } from '@root/src/domain/realTime/realTimeProvider'
import { useContext, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Rooms, ServerToClientEvents } from '@domain/realTime/types'
import { getUserId } from '@root/src/domain/generic/selectors'
import { useSelector } from 'react-redux'

const DOCUMENT_TITLE = 'myIMO | McKinsey & Company'
const REFRESH_DELAY = 2000

export const useImportantNotifications = (enabled = true) => {
  const userId = useSelector(getUserId)
  const queryClient = useQueryClient()
  const { joinRoom, leaveRoom, authenticated, socket } = useContext(RealTimeContext)

  useEffect(() => {
    if (authenticated && enabled) {
      // Register marked as done and refresh as will receive events for all users, indicated by using `-1` as teamId
      // Refresh is a command and no infomation is sent and marked as done only sends an array of uuids which is safe
      // for all users to receive
      joinRoom({ room: Rooms.ImportantNotificationMarkedAsDone, teamId: -1 })
      joinRoom({ room: Rooms.ImportantNotificationRefresh, teamId: -1 })
      // These rooms are user specific not team specific, so we pass the userId as the team ids which is used to register
      // the user to their own room. Events that are recieved are specific to the user and added to the list of important
      // notification events (uuids and type only, no other important information)
      joinRoom({ room: Rooms.importantNotificationCreated, teamId: userId })
      joinRoom({ room: Rooms.ImportantNotificationRefresh, teamId: userId })

      return () => {
        leaveRoom({ room: Rooms.ImportantNotificationMarkedAsDone, teamId: -1 })
        leaveRoom({ room: Rooms.importantNotificationCreated, teamId: userId })
        leaveRoom({ room: Rooms.ImportantNotificationRefresh, teamId: userId })
        leaveRoom({ room: Rooms.ImportantNotificationRefresh, teamId: -1 })
      }
    }
  }, [authenticated, userId, enabled])

  function updateImportantNotifications({ payload: { id } }: { payload: { id: string[] } }) {
    // Sets a timeout to ensure that the action has completed before refreshing the state
    setTimeout(async () => {
      await queryClient.invalidateQueries(['notifications-hub', 'page'])
      queryClient.setQueryData(
        ['notifications-hub', 'important'],
        (current: { notificationEventId: string; typeId: number }[] = []) => {
          return current.filter((notification) => !id.includes(notification.notificationEventId))
        },
      )
    }, REFRESH_DELAY)
  }

  function addImportantNotifications({ payload }: { payload: { notificationEventId: string; typeId: number } }) {
    // Sets a timeout to ensure that the action has completed before refreshing the state
    setTimeout(async () => {
      await queryClient.invalidateQueries(['notifications-hub', 'page'])
      queryClient.setQueryData(
        ['notifications-hub', 'important'],
        (current: { notificationEventId: string; typeId: number }[] = []) => {
          if (current.some((notification) => notification.notificationEventId === payload.notificationEventId)) {
            return [...current]
          }
          return [...current, payload]
        },
      )
    }, REFRESH_DELAY)
  }

  function refreshImportantNotifications() {
    // Sets a timeout to ensure that the action has completed before refreshing the state
    setTimeout(() => {
      queryClient.invalidateQueries(['notifications-hub', 'page'])
      queryClient.invalidateQueries(['notifications-hub', 'important'])
    }, REFRESH_DELAY)
  }

  useEffect(() => {
    if (socket && enabled) {
      socket.on(ServerToClientEvents.ImportantNotificationMarkedAsDone, updateImportantNotifications)
      socket.on(ServerToClientEvents.importantNotificationCreated, addImportantNotifications)
      socket.on(ServerToClientEvents.ImportantNotificationRefresh, refreshImportantNotifications)
      return () => {
        socket.off(ServerToClientEvents.ImportantNotificationMarkedAsDone, updateImportantNotifications)
        socket.off(ServerToClientEvents.importantNotificationCreated, addImportantNotifications)
        socket.off(ServerToClientEvents.ImportantNotificationRefresh, refreshImportantNotifications)
      }
    }
  }, [socket, enabled])

  const { data } = useQuery(
    ['notifications-hub', 'important'],
    async () => {
      const { data } = await api.get<{ id: string; typeId: number }[]>({ url: 'notifications-hub/important' })
      return data
    },
    { useErrorBoundary: false, enabled },
  )

  useEffect(() => {
    if (enabled) {
      if (data?.length) {
        const count = data.length > 99 ? '99+' : data.length
        document.title = `(${count}) ${DOCUMENT_TITLE}`
      } else {
        document.title = DOCUMENT_TITLE
      }
    }
  }, [enabled, data])

  return { data }
}
