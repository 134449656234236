import { put, call, select } from 'redux-saga/effects'
import * as actions from '../actions/actions'
import { getMutualDiscoveryApi, getTSAv2Api } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getTSAActivityLogs, getTSAv2Data } from '@domain/myImoClient/TSAv2/selectors'
import {
  getTransformedUpdateData,
  getTransformedCreateData,
  getUpdatedTSA,
} from '@domain/myImoClient/TSAv2/sagas/utils'
import { omit, first } from 'lodash/fp'
import {
  setTSAProcessesFromLibrary,
  fetchTSA as fetchTSAAction,
  setSnapshotMinDate,
  setMasterListTSAStatus,
  fetchActivityLogPending,
} from '../actions/actions'
import { transformTsaProcessFromLibraryToAdd } from '@myImoClient/components/TSAv2/shared/utils'
import { FIELDS_WITH_DEPENDENCIES } from '@myImoClient/components/TSAv2/shared/constants'
import { ACTIVITY_LOG_PAGE_SIZE } from '@domain/myImoClient/common/constants'
import { getTSAActivityLogSource } from '@domain/myImoClient/TSAv2/selectors/utils'
import { getSelectedTeamByTeamId } from '@generic/selectors'
import { CustomHttpClientErrorType } from '@common/net/HttpClient'

export const fetchTSA = createSaga(function* fetchTSA({ payload }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload

  const api = yield getTSAv2Api()
  const tsa = yield call(
    api.request('fetchTSAv2', {
      query: { teamId },
    }),
  )

  yield put(actions.setTSA({ tsa }))
}, true)

export const fetchTeamResourcesTSA = createSaga(function* fetchTeamResourcesTSA(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTSAv2Api()
  const tsa = yield call(api.request('fetchTeamResourcesTSA'))

  yield put(actions.setTSA({ tsa: tsa.rows }))
},
true)

export const createTSA = createSaga(
  function* createTSA({ payload }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const { data, teamId, queryTeamId } = payload

    const selectedTeam = yield select(getSelectedTeamByTeamId, teamId)

    const api = yield getTSAv2Api()
    try {
      const response = yield call(
        api.request('createTSAv2', {
          query: { teamId: queryTeamId },
          body: omit(['id', 'isNew', 'team'], getTransformedCreateData(data)),
        }),
      )

      yield put(fetchActivityLogPending({ teamId, rowId: response.id, selectedTeam }))

      yield put(actions.fetchTSA({ teamId }))
    } catch (e) {
      yield put(actions.deleteTSASuccess({ id: data.id }))

      throw e
    }
  },
  true,
  (e: CustomHttpClientErrorType) => e,
)

export const deleteTSA = createSaga(function* deleteTSA({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, id } = payload
  const api = yield getTSAv2Api()

  yield call(
    api.request('deleteTSAv2', {
      query: {
        teamId,
        id,
      },
    }),
  )

  yield put(actions.deleteTSASuccess({ id }))
})

export const updateTSA = createSaga(
  function* updateTSA({ payload }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const { teamId, data, field, id, customColumn, newTeamId, queryTeamId, isAllTSAs } = payload
    const api = yield getTSAv2Api()
    const tsaV2Data = yield select(getTSAv2Data)
    const transformedData = getTransformedUpdateData({ data, field, customColumn })
    const TSATeamId = isAllTSAs ? queryTeamId : teamId
    const selectedTeam = yield select(getSelectedTeamByTeamId, queryTeamId)

    yield call(
      api.request('updateTSAv2', {
        query: {
          teamId,
          id: id,
        },
        body: transformedData,
      }),
    )

    yield put(fetchActivityLogPending({ teamId: queryTeamId, rowId: id, selectedTeam }))

    const needToFetchNewData = FIELDS_WITH_DEPENDENCIES.includes(first(Object.keys(data)) || '')

    if (needToFetchNewData) {
      yield put(actions.fetchTSA({ teamId: TSATeamId }))

      return
    }

    if (newTeamId !== teamId && field === 'team') {
      yield put(actions.fetchTSA({ teamId: queryTeamId }))

      return
    }

    const updatedTSAs = tsaV2Data.map((tsa: $TSFixMe) => (tsa.id === id ? getUpdatedTSA(tsa, data) : tsa))

    yield put(actions.setTSA({ tsa: updatedTSAs }))
  },
  false,
  (e: CustomHttpClientErrorType) => e,
)

export const fetchTSAProcessesFromLibrary = createSaga(function* fetchTSAProcessesFromLibrary({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload
  const api = yield getMutualDiscoveryApi()

  const data = yield call(
    api.request('getMutualDiscoveryData', {
      query: { teamId },
    }),
  )

  yield put(setTSAProcessesFromLibrary(data))
})

export const addTSAProcessesFromLibrary = createSaga(function* addTSAProcessesFromLibrary({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data, callback, teamIdToFetchTableData } = payload
  const api = yield getTSAv2Api()

  yield call(
    api.request('createTSAProcessFromLib', {
      query: { teamId },
      body: transformTsaProcessFromLibraryToAdd(data),
    }),
  )

  yield put(fetchTSAAction({ teamId: teamIdToFetchTableData }))

  callback()
})

export const getSnapshotMinDate = createSaga(function* getSnapshotMinDate({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload
  const api = yield getTSAv2Api()

  const response = yield call(api.request('getSnapshotMinDate', { query: { teamId } }))

  const minDate = response?.minDate ? new Date(response.minDate) : new Date()

  yield put(setSnapshotMinDate(minDate))
})

export const getMasterListTSAStatus = createSaga(function* getMasterListTSAStatus({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = payload
  const api = yield getTSAv2Api()

  const statuses = yield call(api.request('getMasterListTSAStatus', { query: { teamId } }))

  yield put(setMasterListTSAStatus(statuses))
})

export const fetchActivityLog = createSaga(function* fetchActivityLog({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const state: ReturnType<typeof getTSAActivityLogs> = yield select(getTSAActivityLogs)

  const { teamId, rowId } = payload

  const selectedTeam = yield select(getSelectedTeamByTeamId, teamId)

  // @ts-ignore
  const currentTSAActivityLog = state[rowId]

  if (currentTSAActivityLog && !currentTSAActivityLog.hasMore) {
    return
  }

  const offset = currentTSAActivityLog ? currentTSAActivityLog.items.length : 0

  const api = yield getTSAv2Api()

  const source = getTSAActivityLogSource({ selectedTeamType: selectedTeam.teamType })

  const tsaV2ActivityLog = yield call(
    api.request('fetchTSAv2ActivityLog', { query: { teamId, rowId, offset, limit: ACTIVITY_LOG_PAGE_SIZE, source } }),
  )

  yield put(actions.fetchActivityLogSuccess({ data: tsaV2ActivityLog, rowId, offset }))
},
false)
