import styled from 'styled-components'
import { Button, Colors, FileInput } from '@blueprintjs/core'
import { get } from 'lodash'
import Selector from '@shared/Selector'
import { lightBlue, defaultGray, textDarkGrey, white } from '@imo/imo-ui-toolkit'

export const ActionBtnContainer = styled.div`
  display: flex;
  justify-content: ${({ justify }: { justify: string }) => justify || 'space-between'};
  align-items: center;
  width: 100%;
  padding: 0 0 0 20px;
  font-size: 14px;

  .close-btn {
    width: 85px;
  }
`

export const ActionButton = styled(Button)<{ style?: $TSFixMe }>`
  font-size: 14px;
  margin-left: 10px;
  padding: 0;
  height: 30px;
  line-height: 30px;
  width: ${(props) => get(props, ['style', 'width'], 79)}px;
  ${(props) => props.style}
`

export const StyledLabel = styled.span<{ style?: $TSFixMe }>`
  font-size: 14px;
  font-weight: 500;
  ${(props) => props.style}
`
export const ReviewLabel = styled(StyledLabel)`
  color: ${Colors.ORANGE3};
  margin: 15px 0;
`

export const LightLabel = styled.span<{ style?: $TSFixMe }>`
  font-weight: 400;
  line-height: 1.43;
  margin-top: 10px;
  ${(props) => props.style}
`

export const OverlayHeader = styled.div<{ style?: $TSFixMe }>`
  flex-shrink: 0;
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0 16px;
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
  color: ${textDarkGrey};
  align-items: center;
  ${(props) => props.style}

  h3 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    margin: 0;
  }

  > span {
    margin-right: 8px;
  }
`

export const OverlayContainer = styled.div<{ isSuccess?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${white};
  border-radius: 4px;
  width: ${({ isSuccess }) => (isSuccess ? 400 : 660)}px;
  max-height: 95vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const OverlayContentWrapper = styled.div<{ style?: $TSFixMe }>`
  overflow: auto;
  display: flex;
  width: 100%;
  padding: 16px 20px 20px 16px;
  font-size: 14px;
  line-height: 18px;
  color: ${textDarkGrey};
  box-sizing: border-box;
  ${(props) => props.style}

  .warning-note {
    margin: 10px 0 0 0;

    .bp4-icon svg {
      color: ${Colors.ORANGE2};
    }
  }

  .success-callout {
    line-height: 15px;
  }

  .tick-circle-icon {
    color: ${Colors.GREEN3};
  }
`

export const OverlaySuccessWrapper = styled(OverlayContentWrapper)`
  flex-direction: column;
`

export const OverlayStatusWrapper = styled(OverlayContentWrapper)`
  flex-direction: column;
  padding: 16px 20px 20px 20px;

  .bp4-callout {
    margin-top: 10px;
  }
`

export const OverlayContent = styled.div`
  margin-left: 38px;

  .list-container {
    padding: 0 0 0 21px;
  }

  > ul {
    list-style-type: disc;
    margin: 0;

    li {
      font-size: 14px;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  p {
    margin: 0 0 10px 13px;
  }

  &.batch-upload-info-content {
    section {
      background-color: ${lightBlue};
      padding: 7px 7px 10px 16px;
      margin-bottom: 10px;
      margin-left: 5px;
      border-radius: 6px;
    }

    .btn-link {
      background: none !important;
      border: none;
      padding: 0 3px;
      color: ${Colors.BLUE3};

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 57px;
`

export const ErrorWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
  display: flex;
  flex-direction: column;

  > ul {
    padding: 4px 15px 0 0;
    margin: 0;
    list-style: none;

    li:before {
      content: '•';
      font-size: 14px;
      padding-right: 4px;
    }
  }
`

export const ErrorsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 50px 0 0;
  margin: 20px 0 0 0;
`

export const StyledFileInput = styled(FileInput)<$TSFixMe>`
  height: 40px;
  width: 100%;
  margin: 15px 0 0 0;
`

export const StyledStatusLabel = styled.div`
  width: 100%;
  text-align: center;
  color: ${defaultGray};
  font-size: 10px;
  margin: 10px 0 5px 0;

  &.danger {
    color: ${Colors.RED3};
  }

  &.success {
    color: ${Colors.GREEN3};
  }
`

export const ProgressUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 96px;
`

export const StyledSelect = styled(Selector)`
  margin-top: 15px;

  .bp4-transition-container {
    width: calc(100% - 76px);

    .batch-upload-select-popover {
      width: 100%;
    }
  }
`
