import { call, put, select } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getInitiativeActivityLogApi } from '@common/net'
import { setInitiativeActivityLog } from '../actions/actions'
import { INITIATIVE_LOG_PAGE_SIZE } from '@domain/myImoClient/common/initiativeActivityLog/constants'
import { getInitiativeActivityLogs } from '@domain/myImoClient/common/initiativeActivityLog/selectors'

export const fetchInitiativeActivityLogSaga = createSaga(function* fetchInitiativeActivityLogSaga(action: {
  payload: { initiativeId: number; teamId: number }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const state: ReturnType<typeof getInitiativeActivityLogs> = yield select(getInitiativeActivityLogs)

  const { teamId, initiativeId } = action.payload

  const currentInitiativeLog = state[initiativeId]

  if (currentInitiativeLog && currentInitiativeLog.hasMore === false) {
    return
  }

  const pageNo = currentInitiativeLog ? currentInitiativeLog.items.length : 0

  const initiativeActivityLogApi = yield getInitiativeActivityLogApi()

  const data = yield call(
    initiativeActivityLogApi.request('fetchInitiativeActivityLog', {
      query: { teamId, initiativeId, limit: INITIATIVE_LOG_PAGE_SIZE, offset: pageNo },
    }),
  )

  yield put(setInitiativeActivityLog({ initiativeId, data, offset: pageNo }))
},
false)
