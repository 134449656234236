import { map } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Colors } from '@blueprintjs/core'
import Button from './Button'
import { useSelector } from 'react-redux'
import { ACTIVE_SIDER_ICON_COLOR } from '@helpers/constants'
import { getIsLaunched } from '@domain/instanceConfig/selectors'

interface Props {
  menuData?: $TSFixMe[]
  isActive?: (...args: $TSFixMe[]) => $TSFixMe
  isDisabled?: (...args: $TSFixMe[]) => $TSFixMe
  onClick?: (item: $TSFixMe) => $TSFixMe
  keyPrefix?: string
  isShown?: (...args: $TSFixMe[]) => $TSFixMe
  isHasError?: (...args: $TSFixMe[]) => $TSFixMe
}

const ButtonContainer = styled.div`
  position: relative;
`

const Badge = styled.div`
  position: absolute;
  top: -8px;
  right: -10px;
  background: #e86826; // Not a core BP color;
  color: ${Colors.WHITE};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  height: 16px;
  min-width: 16px;
  padding: 0;
  margin: 0;
  border-radius: 9px;
  padding: 0px 4px;
`

const ButtonsList = ({
  menuData = [],
  isActive = () => false,
  isDisabled = () => true,
  isHasError = () => false,
  isShown = () => true,
  onClick = () => {},
  keyPrefix,
}: Props) => {
  const isLaunched = useSelector(getIsLaunched)
  const handleDisabling = (message: $TSFixMe) => isDisabled(message)
  const handleActive = (data: $TSFixMe) => isActive(data)
  const handleShowing = (data: $TSFixMe) => isShown(data)
  const handleHasError = (data: $TSFixMe) => isHasError(data)

  const visibleUserIcons = useMemo(() => {
    if (isLaunched) {
      return menuData.filter((item: $TSFixMe) => {
        return !handleDisabling(item.pageName)
      })
    }

    return menuData
  }, [menuData, isLaunched])

  const getIconColor = (item: $TSFixMe) => {
    if (handleDisabling(item.pageName)) {
      return Colors.GRAY1
    }

    return handleActive(item) ? ACTIVE_SIDER_ICON_COLOR : Colors.GRAY3
  }

  return (
    <>
      {map(visibleUserIcons, (item, index) => (
        <Button
          onClick={() => onClick(item)}
          tooltipMessage={item.tooltipMessage}
          isShown={handleShowing(item.pageName)}
          isDisabled={handleDisabling(item.pageName)}
          isHasError={handleHasError(item.pageName)}
          key={`${keyPrefix}-${index}`}
          isPopover={item.hasPopover}
          id={item.id}
          path={item.path}
          contentStyles={item.contentStyles}
          isActive={handleActive(item)}
          forceClickable={item.forceClickable}
        >
          <ButtonContainer>
            {item.badgeData && <Badge>{item.badgeData}</Badge>}
            {item.menuIcon(getIconColor(item))}
          </ButtonContainer>
        </Button>
      ))}
    </>
  )
}

ButtonsList.defaultProps = {
  menuData: [],
  isActive: () => false,
  isDisabled: () => true,
  isHasError: () => false,
  isShown: () => true,
  onClick: () => {},
  keyPrefix: '',
}

export default ButtonsList
