import React from 'react'

import { BackgroundSceneStyled, BackgroundUISceneStyled } from './index.styles'
import myimoRays from '@assets/svg/myimo-rays.svg'
import myimoElements from '@assets/svg/myimo-elements.svg'

const BackgroundScene = ({ children, onLoad }: { children: React.ReactNode; onLoad: () => void }) => {
  const handleSvgLoad = () => {
    onLoad()
  }

  return (
    <BackgroundSceneStyled>
      <BackgroundUISceneStyled>
        <img
          src={myimoRays}
          alt="Background Rays"
          style={{ display: 'none', visibility: 'hidden' }}
          onLoad={handleSvgLoad} // Trigger when SVG is loaded
        />
        <img
          src={myimoElements}
          alt="Background Elements"
          style={{ display: 'none', visibility: 'hidden' }}
          onLoad={handleSvgLoad} // Trigger when SVG is loaded
        />
        {children}
      </BackgroundUISceneStyled>
    </BackgroundSceneStyled>
  )
}

export default BackgroundScene
