import { handleActions } from 'redux-actions'
import * as constants from '@domain/myImoClient/TSAv2/actions/actionTypes'
import { flattenProcessFromLibrary, getMDPARowNodeId } from '@mutualDiscovery/utils'
import { filterTsaProcessesFromLibrary } from '@domain/myImoClient/TSAv2/sagas/utils'
import { ACTIVITY_LOG_PAGE_SIZE } from '@domain/myImoClient/common/constants'

interface ITSAv2State {
  tsa: $TSFixMe[]
  tsaProcessesFromLibrary: $TSFixMe[]
  tsaMasterListTabStatus: $TSFixMe[]
  tsaV2ActivityLog: $TSFixMe
}

export const initialState = {
  tsa: [],
  tsaProcessesFromLibrary: [],
  snapshotMinDate: null,
  selectedTsaID: null,
  tsaMasterListTabStatus: [],
  tsaV2ActivityLog: {},
}

export const TSAActivityLogInitialState = { hasMore: true, items: [] }

const TSAv2Reducer = handleActions<ITSAv2State, $TSFixMe>(
  {
    [constants.SET_TSA]: (state, action) => {
      const { tsa } = action.payload

      return {
        ...state,
        tsa,
      }
    },
    [constants.RESET_TSA]: (state) => ({
      ...state,
      tsa: initialState.tsa,
    }),

    [constants.CREATE_TSA_SUCCESS]: (state, action) => {
      const { data } = action.payload
      const { tsa } = state
      const newTSAs = [...tsa, data]

      return {
        ...state,
        tsa: newTSAs,
      }
    },

    [constants.DELETE_TSA_SUCCESS]: (state, action) => {
      const { id } = action.payload
      const { tsa } = state

      const filteredTSAs = tsa.filter((item) => item.id !== id)

      return {
        ...state,
        tsa: filteredTSAs,
      }
    },
    [constants.SET_TSA_PROCESSES_FROM_LIBRARY]: (state, { payload }) => {
      const flatList = flattenProcessFromLibrary(payload)

      return {
        ...state,
        tsaProcessesFromLibrary: filterTsaProcessesFromLibrary(flatList),
      }
    },
    [constants.UPDATE_TSA_PROCESSES_FROM_LIBRARY]: (state, { payload }) => {
      const { rowNodeId, selected } = payload

      return {
        ...state,
        tsaProcessesFromLibrary: state.tsaProcessesFromLibrary.map((process) =>
          getMDPARowNodeId({ data: process }) === rowNodeId && !process.disabled
            ? {
                ...process,
                selected,
              }
            : process,
        ),
      }
    },
    [constants.RESET_TSA_PROCESSES_FROM_LIBRARY]: (state) => ({
      ...state,
      tsaProcessesFromLibrary: [],
    }),
    [constants.SET_SNAPSHOT_MIN_DATE]: (state, { payload }) => {
      return {
        ...state,
        snapshotMinDate: payload,
      }
    },
    [constants.SET_SELECTED_TSA]: (state, { payload }) => {
      return {
        ...state,
        selectedTsaID: payload,
      }
    },
    [constants.SET_MASTER_LIST_TSA_STATUS]: (state, { payload }: $TSFixMe) => {
      return {
        ...state,
        tsaMasterListTabStatus: payload,
      }
    },
    [constants.RESET_MASTER_LIST_TSA_STATUS]: (state) => {
      return {
        ...state,
        tsaMasterListTabStatus: [],
      }
    },
    [constants.FETCH_ACTIVITY_LOG_PENDING]: (state, { payload }) => {
      const { rowId } = payload

      return { ...state, tsaV2ActivityLog: { [rowId]: TSAActivityLogInitialState } }
    },
    [constants.FETCH_ACTIVITY_LOG_SUCCESS]: (state, { payload }: $TSFixMe) => {
      const { data, rowId, offset } = payload

      const existingForTSA = state?.tsaV2ActivityLog[rowId] ?? TSAActivityLogInitialState

      const hasMore = data.total > offset + ACTIVITY_LOG_PAGE_SIZE

      return { ...state, tsaV2ActivityLog: { [rowId]: { items: [...existingForTSA.items, ...data.logs], hasMore } } }
    },
  },
  initialState,
)

export default TSAv2Reducer
