import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { ACTIVITY_LOG_PAGE_SIZE } from '@domain/myImoClient/common/constants'

export interface IProjectActivityLogState {
  [key: string]: $TSFixMe
}

export const initialState = {}
export const projectActivityLogInitialState = { hasMore: true, items: [] }

const projectActivityLogReducer = handleActions<
  IProjectActivityLogState,
  { keyProcessProjectId: number; offset: number; data: { logs: $TSFixMe[]; total: number } }
>(
  {
    [constants.FETCH_PROJECT_ACTIVITY_LOG]: (state, action) => {
      const { keyProcessProjectId } = action.payload

      // Clear on initial fetch
      return { ...state, [keyProcessProjectId]: projectActivityLogInitialState }
    },
    [constants.SET_PROJECT_ACTIVITY_LOG]: (state, action) => {
      const { keyProcessProjectId, data, offset } = action.payload

      const existingForProject = state[keyProcessProjectId]
        ? state[keyProcessProjectId]
        : projectActivityLogInitialState

      const hasMore = data.total > offset + ACTIVITY_LOG_PAGE_SIZE

      return { ...state, [keyProcessProjectId]: { items: [...existingForProject.items, ...data.logs], hasMore } }
    },
  },
  initialState,
)

export default projectActivityLogReducer
