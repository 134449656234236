import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TSAv2Api extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  fetchTSAv2 = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `teams/${query.teamId}/tsa/v2/deliverable` })
  }

  fetchTeamResourcesTSA = () => this.call({ pathname: 'team-resources/tsa/v2' })

  createTSAv2 = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/tsa/v2/deliverable`,
      },
      {
        method: 'POST',
        body: body,
      },
    )
  }

  updateTSAv2 = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/tsa/v2/deliverable/${query.id}`,
      },
      {
        method: 'PATCH',
        body: body,
      },
    )
  }

  deleteTSAv2 = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/tsa/v2/deliverable/${query.id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  createTSAProcessFromLib = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/tsa/v2/deliverable/import`,
      },
      {
        method: 'POST',
        body: body,
      },
    )
  }

  getBatchUploadTemplate = ({ query }: $TSFixMe) => {
    const { teamId } = query
    const url = `${this.host}/files/team/${teamId}/tsa-deliverable-v2-template`

    return this.fetch(url, { method: 'GET', headers: {} })
  }

  batchFileUpload = ({ query, body }: $TSFixMe) => {
    const { teamId } = query

    return this.call(
      { pathname: `files/team/${teamId}/tsa-deliverable-v2-upload` },
      {
        method: 'POST',
        body,
        shouldSubstituteHeader: false,
      },
    )
  }

  getSnapshotMinDate = ({ query }: $TSFixMe) => {
    const { teamId } = query

    return this.call({ pathname: `/teams/${teamId}/tsa/v2/deliverable/export/first-available-date` })
  }

  getMasterListTSAStatus = ({ query }: $TSFixMe) => {
    const { teamId } = query

    return this.call({ pathname: `/teams/${teamId}/my-team-deliverable-status` })
  }

  fetchTSAv2ActivityLog = ({ query }: $TSFixMe) => {
    const { teamId, rowId, offset, limit, source } = query

    return this.call({
      pathname: `/teams/${teamId}/tsa/v2/deliverable/${rowId}/activity-logs`,
      query: {
        limit,
        offset,
        source,
      },
    })
  }
}
