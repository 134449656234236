import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TaskActivityLogApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch)

    this.host = Config.API_SERVER
    this.routePrefix = ''
  }

  fetchTaskActivityLog = ({ query }: $TSFixMe) => {
    const { teamId, keyProcessId, keyProcessProjectId, taskId, limit, offset } = query

    return this.call({
      pathname: `/teams/${teamId}/team-key-process-v2/${keyProcessId}/projects/${keyProcessProjectId}/tasks/${taskId}/activity-logs`,
      query: {
        limit,
        offset,
      },
    })
  }
}
